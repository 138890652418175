import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container } from './closed.module.scss';
import { IPage } from '../../../models/page.model';

import ValentinesLayout from '../../../layouts/valentines-layout';

interface IValentinesClosedPageProps {
    readonly data: {
        page: IPage | null;
    };
}

const Closed: React.FC<IValentinesClosedPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;

    return (
        <ValentinesLayout
            page={page}
            className={container}
            headerProps={{
                titleFirstLine: t('valentines.closed.title.1'),
                titleSecondLine: t('valentines.closed.title.2'),
            }}
            pageType={'closed'}
        />
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default Closed;
